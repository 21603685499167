<template>
  <div>
    <div v-if="!loading">
      <v-toolbar dense flat color="tranpsarent">
        <v-btn icon @click="$router.go(-1)"><v-icon>arrow_back</v-icon></v-btn>
        <v-toolbar-title>{{ region.code }} - {{ region.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card flat color="transparent" class="py-0 my-0">
        <v-card-text class="py-0 my-0">
          <v-row>
            <v-col cols="12" sm="12" md="4">
              <v-card-title style="color: var(--v-primaryText-base)">
                Countries
              </v-card-title>
              <v-text-field
                outlined
                dense
                label="Search"
                prepend-inner-icon="search"
                v-model="searchCountry"
                clearable
              ></v-text-field>

              <v-list dense style="max-height: 70vh; overflow-y: auto">
                <v-list-item
                  style="height: 20px"
                  v-for="country in countryList"
                  :key="country.id"
                  @click="routeToCountry(country)"
                >
                  <v-list-item-avatar>
                    <v-img
                      contain
                      v-if="country.code"
                      :src="`https://cdn.loglive.io/flags/4x3/${country.code.toLowerCase()}.svg`"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ country.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" sm="12" md="8">
              <v-card flat tile color="transparent">
                <v-card-title>
                  Regulatory Bodies
                  <v-btn icon color="primary" @click="addRegulatoryBody()"
                    ><v-icon>add_circle_outline</v-icon></v-btn
                  >
                </v-card-title>
                <v-card-text>
                  <v-data-table
                    :items="regulatoryBodies"
                    :headers="regulatoryHeaders"
                    :loading="loadingRegulatoryBody"
                    style="cursor: pointer"
                  >
                    <template v-slot:[`item.action`]="{ item }">
                      <v-row justify="center">
                        <v-btn icon @click="editRegulatoryBody(item)" small
                          ><v-icon small>edit</v-icon></v-btn
                        >
                        <v-btn
                          icon
                          small
                          class="mr-1"
                          @click="deleteDocument(item)"
                          color="red"
                          ><v-icon small>delete</v-icon></v-btn
                        >
                      </v-row>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
              <v-card flat tile color="transparent">
                <v-card-title>
                  Export Documents
                  <v-btn icon color="primary" @click="addDocument('Export')"
                    ><v-icon>add_circle_outline</v-icon></v-btn
                  >
                </v-card-title>
                <v-card-text>
                  <v-data-table
                    :headers="documentHeaders"
                    :items="exportDocuments"
                    :loading="loadingDocuments"
                    :search="searchOriginDocuments"
                    style="cursor: pointer"
                  >
                    <template v-slot:[`item.action`]="{ item }">
                      <v-row>
                        <v-btn icon @click="editDocument(item)" small
                          ><v-icon small>edit</v-icon></v-btn
                        >
                        <v-btn
                          icon
                          small
                          class="mr-1"
                          @click="deleteDocument(item)"
                          color="red"
                          ><v-icon small>delete</v-icon></v-btn
                        >
                      </v-row>
                    </template>
                    <template v-slot:[`item.required`]="{ item }">
                      <v-icon v-if="item.required" color="blue"
                        >verified</v-icon
                      >
                    </template>

                    <template v-slot:[`item.integrationType`]="{ item }">
                     <span style="text-transform: capitalize">{{item.integrationType}}</span>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
              <v-card flat tile color="transparent">
                <v-card-title>
                  Import Documents
                  <v-btn
                    icon
                    color="primary"
                    @click="addDocument('Import')"
                    ><v-icon>add_circle_outline</v-icon></v-btn
                  >
                </v-card-title>
                <v-card-text>
                  <v-data-table
                    :headers="documentHeaders"
                    :items="importDocuments"
                    :loading="loadingDocuments"
                    :search="searchDestinationDocuments"
                    style="cursor: pointer"
                  >
                    <template v-slot:[`item.action`]="{ item }">
                      <v-row>
                        <v-btn icon @click="editDocument(item)" small
                          ><v-icon small>edit</v-icon></v-btn
                        >
                        <v-btn
                          icon
                          small
                          class="mr-1"
                          @click="deleteDocument(item)"
                          color="red"
                          ><v-icon small>delete</v-icon></v-btn
                        >
                      </v-row>
                    </template>
                    <template v-slot:[`item.required`]="{ item }">
                      <v-icon v-if="item.required" color="blue"
                        >verified</v-icon
                      >
                    </template>
                      <template v-slot:[`item.integrationType`]="{ item }">
                     <span style="text-transform: capitalize">{{item.integrationType}}</span>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <!-- Regulatory Body -->
      <v-dialog
        v-model="regulatoryBodyModal"
        persistent
        width="400px"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <v-card>
          <RegulatoryBody
            :item="regulatoryBodyItem"
            @updated="
              getRegulatoryBodies(),
                (regulatoryBodyModal = false),
                (regulatoryBodyItem = {})
            "
            @close="(regulatoryBodyModal = false), (regulatoryBodyItem = {})"
          />
        </v-card>
      </v-dialog>

      <!-- Document Modal -->
      <v-dialog
        v-model="documentModal"
        persistent
        width="600px"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <v-card>
          <v-toolbar flat color="transparent">
            <v-toolbar-title>Manage Document</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text @click="(documentModal = false), (item = {})">X</v-btn>
          </v-toolbar>
          <v-card-text>
            <v-list class="text-center">
              <v-autocomplete
                label="Regulatory Body"
                :items="regulatoryBodies"
                item-value="id"
                item-text="name"
                outlined
                dense
                v-model="item.regulatoryBody"
                return-object
              >
               <template v-slot:item="data">
                    <v-list-item-avatar>
                      <v-img
                      v-if="data.item.logo"
                        contain
                        :src="data.item.logo"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
              </v-autocomplete>
              <v-text-field outlined dense label="Document Name" v-model="item.name"></v-text-field>
                <v-switch label="Required" v-model="item.required"></v-switch>
                <v-subheader>Quantity Type</v-subheader>
                <v-btn-toggle v-model="item.quantityType" mandatory>
                  <v-btn value="Shipment" style="text-transform: none">
                    <span>Per Shipment</span>
                  </v-btn>
                  <v-btn value="Container" style="text-transform: none">
                    <span>Per Container</span>
                  </v-btn>
                </v-btn-toggle>
                <v-subheader>Submission/Integration Type</v-subheader>

                <v-btn-toggle v-model="item.integrationType" mandatory>
                  <v-btn value="manual" style="text-transform: none">
                    <span>Manual</span>
                  </v-btn>
                  <v-btn value="api" style="text-transform: none">
                    <span>REST API</span>
                  </v-btn>
                   <v-btn value="soap" style="text-transform: none">
                    <span>SOAP API</span>
                  </v-btn>
                   <v-btn value="edifact" style="text-transform: none">
                    <span>EDIFACT</span>
                  </v-btn>
                </v-btn-toggle>
            </v-list>
            <v-row justify="center" class="mt-2">
              <v-btn
                text
                color="primary"
                :loading="savingDocument"
                :disabled="!item.name || !item.regulatoryBody"
                @click="saveDocument"
                >Save</v-btn
              >
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div
      v-else
      style="height: 90vh; padding-top: 0; margin-top: 0"
      class="text-center"
    >
      <!-- <vue-element-loading
        duration="1"
        active
        spinner="bar-fade-scale"
        color="var(--v-primary-base)"
        background-color="transparent"
        text="Loading Region"
      /> -->
       <valhalla-loading />
    </div>
  </div>
</template>

<script>
import RegulatoryBody from "./RegulatoryBodyModal.vue";
export default {
  components: {
    RegulatoryBody,
  },
  data: () => ({
    allDocuments: [],
    documents: [],
    documentModal: false,
    documentHeaders: [
      {
        text: "Action",
        align: "center",
        sortable: false,
        value: "action",
      },
       {
        text: "Regulatory Body",
        value: "regulatoryBody.name",
        align: "center",
      },
      {
        text: "Name",
        value: "name",
        align: "center",
      },
      {
        text: "Quantity Type",
        value: "quantityType",
        align: "center",
      },
      {
        text: "Required",
        value: "required",
        sortable: false,
        align: "center",
      },
      {
        text: "Submission/Integration",
        value: "integrationType",
        align: "center"
      },
    ],
    regulatoryHeaders:[
      {
        text: "Action",
        align: "center",
        sortable: false,
        value: "action",
      },
      {
        text: "Name",
        value: "name",
        align: "center",
      },
      {
        text: "Website",
        value: "website",
        align: "center",
      }
    ],
    item: {},
    loading: false,
    loadingDocuments: false,
    loadingRegulatoryBody: false,
    region: {},
    regionDocuments:[],
    regulatoryBodies: [],
    regulatoryBodyModal: false,
    regulatoryBodyItem: {},
    savingDocument: false,
    searchCountry: null,
    searchOriginDocuments: null,
    searchDestinationDocuments: null,
  }),
  watch: {
    "$route.params.id": {
      immediate: true,
      handler: function (val) {
        if (val) this.load(val);
      },
    },
  },
  computed: {
    countryList() {
      let result =
        this.region && this.region.code
          ? this.region.countryRegions.map((country) => {
              return {
                id: country.id,
                code: country.code,
                name: country.name,
              };
            })
          : [];
      if (this.searchCountry) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchCountry.toLowerCase())
        );
      }
      return result;
    },
    importDocuments() {
      let result =
        this.region && this.regionDocuments
          ? this.regionDocuments.filter((x) => x.type == "Import")
          : [];
      return result;
    },
    exportDocuments() {
      let result =
        this.region && this.regionDocuments
          ? this.regionDocuments.filter((x) => x.type == "Export")
          : [];
      return result;
    },
  },
  created() {},
  methods: {
    addDocument(type) {
      this.item = {
        type: type,
        regionId: this.region.id,
        integrationType: "manual",
        quantityType: "Shipment"
      };
      this.documentModal = true;
    },
    addRegulatoryBody() {
      this.regulatoryBodyItem = {
        regionId: this.region.id,
      };
      this.regulatoryBodyModal = true;
    },
    deleteDocument(item) {
      this.$confirm(
        "Are you sure you want to delete this document?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          await this.$API.updateRegionDocument({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
          let index = this.regionDocuments.indexOf(item)
          this.regionDocuments.splice(index,1)
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    deleteRegulatoryBody(item) {
      this.$confirm(
        `Are you sure you want to remove ${item.name}?`,
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          await this.$API.updateRegulatoryBody({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
          this.getRegulatoryBodies();
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "Delete canceled",
          // });
        });
    },
    editDocument(item) {
      this.item = item;
      this.documentModal = true;
    },
    editRegulatoryBody(item) {
      this.regulatoryBodyItem = item;
      this.regulatoryBodyModal = true;
    },
    async getRegulatoryBodies() {
      this.loadingRegulatoryBody = true;
      this.regulatoryBodies = await this.$API.getRegulatoryBodyByRegion(
        this.region.id
      );
      this.loadingRegulatoryBody = false;
    },
    async load(id) {
      this.loading = true;
      this.region = await this.$API.getRegionDetail(id);
      this.regionDocuments = await this.$API.getRegionDocuments(
        this.region.id
      );
      this.getRegulatoryBodies()
      this.loading = false;
    },
    routeToCountry(item) {
      this.$router.push({ path: "/country/" + item.code });
    },
     async saveDocument() {
      this.savingDocument = true;
      this.item.regionId = this.region.id;
      this.item.regulatoryBodyId = this.item.regulatoryBody.id
      if (this.item.id) {
        await this.$API.updateCountryDocument(this.item);
        let find = this.regionDocuments.find(
          (x) => x.id == this.item.id
        );
        let index = this.regionDocuments.indexOf(find);
        this.regionDocuments[index] = this.item;
      } else {
        let result = await this.$API.createCountryDocument(this.item);
        this.item.id = result.id
        this.regionDocuments.push(this.item);
      }
      this.documentModal = false;
      this.item = {};
      this.savingDocument = false;
    },
  },
};
</script>
